import React, { Component, createElement } from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';

const getTransitionStyle = ({ timeout, status }) => getTransitionStyles(timeout)[status];
const getTransitionStyles = timeout => {
  return {
    entering: {
      opacity: 0,
    },
    entered: {
      transition: `opacity ${timeout}ms ease-in-out`,
      opacity: 1,
    },
    exiting: {
      transition: `opacity ${timeout}ms ease-in-out`,
      opacity: 0,
    },
  };
};

const timeout = 300;

class WrapPageElement extends Component {
  render() {
    const transitionProps = {
      timeout: {
        enter: 0,
        exit: timeout,
      },
      appear: true,
      in: true,
      key: this.props.location.pathname,
      exit: false,
    };

    return (
      <TransitionGroup>
        <Transition {...transitionProps}>
          {status =>
            createElement(this.props.pageResources.component, {
              ...this.props,
              ...this.props.pageResources.json,
              transition: {
                status,
                timeout,
                style: getTransitionStyle({ status, timeout }),
              },
            })
          }
        </Transition>
      </TransitionGroup>
    );
  }
}


const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state) {
    location.state.referrer = prevLocation ? prevLocation.pathname : null;
  }
}

const wrapPageElement = ({ props, loader }) => {
  if (props.layout) {
    return undefined;
  }
  return createElement(WrapPageElement, { ...props, loader });
};

export {wrapPageElement, onRouteUpdate};