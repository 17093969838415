// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-conviction-areas-js": () => import("./../../../src/pages/conviction-areas.js" /* webpackChunkName: "component---src-pages-conviction-areas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-technology-for-the-deskless-workforce-2020-js": () => import("./../../../src/pages/technology-for-the-deskless-workforce-2020.js" /* webpackChunkName: "component---src-pages-technology-for-the-deskless-workforce-2020-js" */),
  "component---src-templates-company-press-template-index-js": () => import("./../../../src/templates/CompanyPressTemplate/index.js" /* webpackChunkName: "component---src-templates-company-press-template-index-js" */),
  "component---src-templates-company-template-index-js": () => import("./../../../src/templates/CompanyTemplate/index.js" /* webpackChunkName: "component---src-templates-company-template-index-js" */),
  "component---src-templates-conviction-page-template-index-js": () => import("./../../../src/templates/ConvictionPageTemplate/index.js" /* webpackChunkName: "component---src-templates-conviction-page-template-index-js" */),
  "component---src-templates-deskless-workforce-template-index-js": () => import("./../../../src/templates/DesklessWorkforceTemplate/index.js" /* webpackChunkName: "component---src-templates-deskless-workforce-template-index-js" */),
  "component---src-templates-person-press-template-index-js": () => import("./../../../src/templates/PersonPressTemplate/index.js" /* webpackChunkName: "component---src-templates-person-press-template-index-js" */),
  "component---src-templates-person-template-index-js": () => import("./../../../src/templates/PersonTemplate/index.js" /* webpackChunkName: "component---src-templates-person-template-index-js" */),
  "component---src-templates-person-thought-template-index-js": () => import("./../../../src/templates/PersonThoughtTemplate/index.js" /* webpackChunkName: "component---src-templates-person-thought-template-index-js" */),
  "component---src-templates-press-page-template-index-js": () => import("./../../../src/templates/PressPageTemplate/index.js" /* webpackChunkName: "component---src-templates-press-page-template-index-js" */),
  "component---src-templates-simple-page-template-index-js": () => import("./../../../src/templates/SimplePageTemplate/index.js" /* webpackChunkName: "component---src-templates-simple-page-template-index-js" */),
  "component---src-templates-tag-template-index-js": () => import("./../../../src/templates/TagTemplate/index.js" /* webpackChunkName: "component---src-templates-tag-template-index-js" */),
  "component---src-templates-thesis-template-index-js": () => import("./../../../src/templates/ThesisTemplate/index.js" /* webpackChunkName: "component---src-templates-thesis-template-index-js" */),
  "component---src-templates-thought-page-template-index-js": () => import("./../../../src/templates/ThoughtPageTemplate/index.js" /* webpackChunkName: "component---src-templates-thought-page-template-index-js" */),
  "component---src-templates-thought-template-index-js": () => import("./../../../src/templates/ThoughtTemplate/index.js" /* webpackChunkName: "component---src-templates-thought-template-index-js" */)
}

